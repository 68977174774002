.titlebar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
  background: #1e1e1e;
  height: 30px;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 0.85rem;
  border-bottom: 1px solid #191d20;
}

.items {
  flex: 1;
  display: flex;
  margin-right: auto;
  margin-left: 0.5rem;
}

.title {
  flex: 1;
  text-align: center;
}

.items > * {
  padding: 0 0.5rem;
  cursor: pointer;
}

.windowButtons {
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: auto;
}

.windowButtons * {
  margin-left: 0.5rem;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  cursor: pointer;
}

.minimize {
  background: #FC6057;
  margin-left: auto;
}

.maximize {
  background: #FEBC2F;
}

.close {
  background: #33C840;
}

@media screen and (max-width: 900px) {
  .items p {
      display: none;
  }
  .title {
      flex: 4;
  }
}
