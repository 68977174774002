* .code-block{
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    border: 0;
    
  }

  .code-block pre {
    padding: 0 !important;
    margin: 0 !important;
  }
  
  span {
    color: white;
    padding-right: 0px;
  }
 
  .code-line {
    display: flex;
    align-items: center;
    height: 23px;
  }
  

  