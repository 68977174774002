.App-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 1000;
    user-select: none;
  }
  
  .App.scrolled .App-header {
    box-shadow: 0 2px 3px rgb(0, 0, 0);
  }
  
  ::selection {
    background: #2A4F76;
  }


  /* width */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #1e1e1e;
  border-left: solid 1px #555;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}