nav ul {
  list-style-type: none;
  overflow: hidden;
  margin: 0;
  padding: 0;
  background-color: #252526;
  align-items: center;
  justify-content: center;
}

nav li {
  float: left;
  width: 140px;
  height: 30px;
  margin: 0;
  padding: 0;
  background-color: #2D2D2D;
  border-right: solid 1px #272728;
}



.nav-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
}

.nav-item a {
  font-size: 0.80rem;
  color: #919191;
  padding: 0;
  text-decoration: none;
  justify-content: flex-start;
}

.nav-item img {
  margin-left: 10px;
  margin-right: 6px;
  height: 0.90rem;
  width: 0.90rem;
}

nav .active li{
  background-color: #1E1E1E;

}